import { render, staticRenderFns } from "./ImportLocationsForm.vue?vue&type=template&id=ca2838f0&scoped=true&"
import script from "./ImportLocationsForm.vue?vue&type=script&lang=js&"
export * from "./ImportLocationsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca2838f0",
  null
  
)

export default component.exports