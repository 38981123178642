<template>
    <div class="text-center">
        <FileInput name="file"
                   ref="file"
                   :button-title="$t('buttons.import_template')"
                   class-name="justify-center"
                   :accept="acceptedFiles"
                   input-id="templates"
                   v-model="file">
            <template slot="icon"
                      v-if="loader">
                <CircularLoader class="mr-2"></CircularLoader>
            </template>
        </FileInput>
        <InformationDialog @close="informationVisibility = false"
                           @visibility="informationVisibility = $event"
                           :dialog="informationVisibility"
                           :text="$t('messages.correct_import_template')"></InformationDialog>
    </div>
</template>

<script>
import FileInput from '@/components/widgets/forms/FileInput';
import CircularLoader from '@/components/widgets/CircularLoader';
import InformationDialog from '@/components/widgets/dialogs/InformationDialog';

export default {
    name: 'ImportTemplateForm',
    components: { FileInput, CircularLoader, InformationDialog },
    data: () => ({
        file: [],
        acceptedFiles: 'application/json',
        loader: false,
        informationVisibility: false
    }),
    watch: {
        file: {
            handler (val) {
                if (val && val.length > 0) {
                    this.importTemplate(val);
                }
            },
            deep: true
        }
    },
    methods: {
        clearFile () {
            this.$refs.file.clearFiles();
        },
        importTemplate (val) {
            this.loader = true;

            this.$store.dispatch('manageTemplate/importTemplate', {
                data: {
                    file: val[0].content.split(',')[1]
                }
            })
                .then(() => {
                    this.informationVisibility = true;
                    this.$emit('fetch');
                    this.clearFile();
                })
                .finally(() => {
                    this.loader = false;
                });
        }
    }
};
</script>

<style scoped>

</style>
