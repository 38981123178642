<template>
    <div class="pa-4" v-if="canImportData">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.data_imports') }}</h1>
        <v-layout align-stretch
                  justify-space-between
                  wrap>
            <v-flex md6
                    class="full-height mb-8"
                    :class="{'pr-4': $vuetify.breakpoint.mdAndUp}">
                <v-card>
                    <v-card-title>
                        <h2 class="font-weight-medium text--l mb-3">{{ $t('configurations.imports.import_customers') }}</h2>
                    </v-card-title>
                    <v-card-text>
                        <ImportCustomersForm></ImportCustomersForm>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex md6
                    class="full-height mb-8"
                    :class="{'pl-4': $vuetify.breakpoint.mdAndUp}">
                <v-card>
                    <v-card-title>
                        <h2 class="font-weight-medium text--l mb-3">{{ $t('configurations.imports.import_sites') }}</h2>
                    </v-card-title>
                    <v-card-text>
                        <ImportSitesForm></ImportSitesForm>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex md6
                    class="full-height mb-8"
                    :class="{'pr-4': $vuetify.breakpoint.mdAndUp}">
                <v-card>
                    <v-card-title>
                        <h2 class="font-weight-medium text--l mb-3">{{ $t('configurations.imports.import_locations') }}</h2>
                    </v-card-title>
                    <v-card-text>
                        <ImportLocationsForm></ImportLocationsForm>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex md6
                    class="full-height mb-8"
                    :class="{'pl-4': $vuetify.breakpoint.mdAndUp}">
                <v-card>
                    <v-card-title>
                        <h2 class="font-weight-medium text--l mb-3">{{ $t('configurations.imports.import_employees') }}</h2>
                    </v-card-title>
                    <v-card-text>
                        <ImportEmployeesForm></ImportEmployeesForm>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex md6
                    :class="{'pr-4': $vuetify.breakpoint.mdAndUp}"
                    class="full-height mb-8">
                <v-card>
                    <v-card-title>
                        <h2 class="font-weight-medium text--l mb-3">{{ $t('buttons.import_template') }}</h2>
                    </v-card-title>
                    <v-card-text>
                        <ImportTemplateForm></ImportTemplateForm>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import ImportCustomersForm from '@/components/customers/imports/ImportCustomersForm';
import ImportSitesForm from '@/components/customers/imports/ImportSitesForm';
import ImportLocationsForm from '@/components/customers/imports/ImportLocationsForm';
import ImportEmployeesForm from '@/components/employees/import/ImportEmployeesForm';
import ImportTemplateForm from '@/components/templatesConfigurations/import/ImportTemplateForm';
import ability from '@/mixins/ability';

export default {
    name: 'Imports',
    mixins: [ability],
    components: { ImportCustomersForm, ImportTemplateForm, ImportSitesForm, ImportLocationsForm, ImportEmployeesForm }
};
</script>

<style scoped>

</style>
