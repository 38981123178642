<template>
    <div>
        <div class="mb-5">
            <GetFile :title="$t('buttons.download_sample')"
                    :loader="sampleLoader"
                    @get-file="getSample"></GetFile>
        </div>
        <div class="text-center">
            <FileInput name="file"
                       ref="file"
                       :button-title="$t('configurations.imports.import_customers')"
                       class-name="justify-center"
                       :accept="acceptedFiles"
                       input-id="customers"
                       v-model="file">
                <template slot="icon"
                            v-if="loader">
                    <CircularLoader class="mr-2"></CircularLoader>
                </template>
            </FileInput>
        </div>
        <InformationDialog @close="informationVisibility = false"
                           @visibility="informationVisibility = $event"
                           :dialog="informationVisibility"
                           :text="$t('messages.correct_import_customers')"></InformationDialog>
    </div>
</template>

<script>
import GetFile from '@/components/widgets/buttons/GetFile';
import FileInput from '@/components/widgets/forms/FileInput';
import CircularLoader from '@/components/widgets/CircularLoader';
import InformationDialog from '@/components/widgets/dialogs/InformationDialog';

export default {
    name: 'ImportCustomersForm',
    components: { GetFile, FileInput, CircularLoader, InformationDialog },
    data: () => ({
        sampleLoader: false,
        dialog: false,
        acceptedFiles: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        file: [],
        loader: false,
        informationVisibility: false
    }),
    watch: {
        file: {
            handler (val) {
                if (val && val.length > 0) {
                    this.importCustomers(val);
                }
            },
            deep: true
        }
    },
    methods: {
        clearFile () {
            this.$refs.file.clearFiles();
        },
        getSample () {
            this.sampleLoader = true;

            return this.$store.dispatch('commonActions/downloadSampleXLSX', {
                kind: 'customers'
            })
                .finally(() => {
                    this.sampleLoader = false;
                });
        },
        importCustomers (val) {
            this.loader = true;

            this.$store.dispatch('customers/importCustomers', val[0].content.split(',')[1])
                .then(() => {
                    this.informationVisibility = true;
                    this.clearFile();
                    this.$emit('fetch');
                })
                .finally(() => {
                    this.loader = false;
                });
        }
    }
};
</script>

<style scoped lang="scss">
</style>
